body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  width: 100vw;
  overflow: hidden;
}

.web3modal-modal-lightbox {
  z-index: 3000 !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.swiper-pagination-bullets {
  display: none;
}

.rs-modal-content {
  border-radius: 0 !important;
  padding: 0 !important;
}
.scaleIn {
  transform: scale(100%) !important;
}
.fadeIn {
  opacity: 1 !important;
}

.rs-modal-backdrop {
  background: rgba(196, 196, 196, 0.01) !important;
  backdrop-filter: blur(3px) brightness(0.8);
  opacity: 1 !important;
}

.ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsisAnimation steps(7, end) 3000ms infinite;
  animation: ellipsisAnimation steps(7, end) 3000ms infinite;
  content: '......'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsisAnimation {
  to {
    width: 25px;
  }
}

@-webkit-keyframes ellipsisAnimation {
  to {
    width: 25px;
  }
}

.currencyInput {
  position: relative;
  display: inline;
}

.currencyInput:before {
  content: '$';
  box-sizing: border-box;
  display: inline;
  position: absolute;
  z-index: 1;
  top: -1px;
  left: 7px;
  font-weight: 700;
}
